/*titulo y contendor principal páginas
*/
:root {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  --color-button: #fdffff;
  --color-black: rgba(0, 0, 0, 0.4);

}

body {
  font-family: 'Titillium Web', sans-serif !important;
}

input,
textarea,
select {
  background: #fff;
  color: #000;
}

.agendas-tabs {
  margin-top: 20px;
}


.link-recordar-ios {
  text-decoration: none;
  color: var(--color-corporativo);
  border: none;
  background-color: transparent;
  font-size: 15px;
}

.cambiar-user-ios {
  background-color: transparent;
  border: none;
  margin-left: 10px;
  color: var(--color-corporativo);
  line-height: 2;
  font-size: 15px;
}

.btn-rojo-xl-tarifas {
  background-color: #ffffff80;
  color: #dc3545;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-family: inherit;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  border-color: #ddd;
  margin-bottom: 20px;
  max-width: 300px;
  min-height: 46px;
}

.container {
  display: inline-block;
  text-align: center !important;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.titulo-pagina {
  display: inline;
  color: var(--color-corporativo);
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  line-height: 20px;
  margin-top: 20px !important;
  text-shadow: 1px 1px #0d2f8a70;
}

.titulo-pagina-pequeno {
  display: inline;
  color: var(--color-corporativo);
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  line-height: 20px;
  text-shadow: 1px 1px #0d2f8a70;
}

/* Datos cliente */
.datosCliente {
  margin-left: 5px;
  margin-top: 10%;
  margin-bottom: 15px;
  align-items: center;

  padding-top: 0px;
  padding-bottom: 5px;
  padding-right: 5px;

}

.modal-footer-roaming .btn-rojo-xl-tarifas {
  background: var(--color-corporativo) !important;

}

.modal-header-roaming {
  background: var(--color-corporativo);
  color: white !important;
}

.modal-header-roaming .modal-cerrar-tarifa {
  color: white !important;
}

.inputs {
  margin-left: 15px;
  border: none;
  background-color: transparent !important;
  text-align: left !important;
}

.cambiarClave {
  text-align: left !important;
  height: 6vh;
  border-bottom: 1px solid #c8c7cc;

}

.acercaDe {
  text-align: left !important;

  height: 6vh;
  margin-top: 10px;
  border-bottom: 1px solid #c8c7cc;
}

/*boton verde icono blanco toda la pantalla*/
.btn-rojo-xl {
  background-color: #ffffff80;
  color: #dc3545;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-family: inherit;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  border-color: #ddd;
  margin-bottom: 20px;
  max-width: 300px;
  min-height: 46px;


}

.btn-verde-xl {
  background-color: #ffffff;
  color: #8fb523;
  border-radius: 5px;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-family: inherit;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  border-color: #ddd;
  margin-bottom: 20px;
  max-width: 300px;
  min-height: 46px;

}


.btn-opc {
  color: blue;
}

.input {
  margin-top: -15px;
}

/*iconos */
.icon-black-tarifas {
  color: black;
  margin-left: 20px;

}

.icon-black {
  color: black;
  margin-left: 10px;
  margin-right: 5px;

}

.icon-eye {
  position: absolute;
  left: auto;
  right: 25px;
  bottom: 14px;
  color: #a09fa3c2;
  font-size: 16px;
}

.icon {
  margin-left: 10px;
}

.icon-movil {
  margin-right: 10px !important;

}

.icon-white {
  color: #ffffff;
}

.icon-btn {
  margin-right: 5px;
  margin-left: 5px;
  color: var(--color-corporativo);
}

.icon-red {
  color: #dc3545;
  margin-right: 5px;
}

.icon-red-ticket {
  color: #dc3545;
  margin-left: 5px;
  margin-right: 5px;
}

.icon-blue {
  color: rgb(37, 40, 226);
  margin-left: 5px;
}

.icon-purple {
  color: purple;
  margin-left: 5px;
}

/* contraseña */



.contraseniaMargin {
  margin-top: 25px;
}

.formulario-contraseña {
  margin-top: 10vh;
  text-align: left;
  margin-bottom: 10vh;
}

.btn-aceptar {
  background-color: #ffffff;
  color: #8fb523;
  border: 1px solid 000000;
  border-radius: 5px;
  width: 45%;
  height: auto;
  font-size: 18px;
  font-family: inherit;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  border-color: #ddd;

}

.btn-rojo-s {
  background-color: #ffffff;
  color: #dc3545;
  border: 1px solid 000000;
  border-radius: 5px;
  width: 45%;
  height: auto;
  font-size: 18px;
  font-family: inherit;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  border-color: #ddd;
}

.botones-linea {
  margin-top: 40px !important;
  margin-left: 10px !important;
  margin-bottom: 10px;
}

.form-in {
  padding-bottom: 5px;

}

.info-contraseña {
  margin-top: 25px;
  margin-bottom: 10px;
  text-align: left;
}

/* acerca de  */
.acerca {
  margin-top: 20px;
  text-align: left;
}

.version {
  margin-top: 30px;
  margin-left: 3px;
  margin-right: 3px;
  text-align: left;
  padding-top: 10px !important;
  padding-left: 10px;
  padding-bottom: 10px;
  border: 1px solid #c8c7cc;
  border-radius: 5px;
  margin-bottom: 15px;
}

.num-version {
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 5px;
  margin-right: 10px;
}

.email {
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 5px;
  padding-bottom: 5px;
  margin-right: 10px;
  padding-top: 5px;

}

/* consumo */
.hidden {
  display: none;
}

.select-telefonos {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: inline;

}

.select-tlf {
  width: 100%;
  margin-top: 10px;
  min-height: 35px;
}

.select-tipo {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  min-height: 35px;

}

.consumo-listado {
  margin-top: 20px;

}

.div-consumo {
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 10px;
}

.detalles-grafico {

  margin-top: 40px;
  text-align: center;
  padding-top: 10px;
  border: 1px solid #c8c7cc;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  margin-bottom: 30px;

}

.detalles-grafico-accion p {
  margin-top: 0px;
  padding-top: 0px;


}

.etiquetas-grafico {
  margin-bottom: 20px;
  text-align: left;
}

/* facturas */

.select-anio {
  text-align: right !important;
  margin-top: 20px;
  margin-left: 5px;
}

.select-anio-text {
  margin-left: 10px;
}


/*formas de pago */



.formapago {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: left;
  border: 1px solid #d0d0d0;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  min-height: 70px;
  display: inline-block;
  box-shadow: 5px 4px 6px 3px #ddd;
  padding-top: 17px;
}

.formapagoRedsys {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  border: 1px solid #d0d0d0;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  min-height: 70px;
  display: inline-block;
  text-align: center;
  box-shadow: 5px 4px 6px 3px #ddd;
  padding-top: 17px;
}

.payment-div {
  margin-top: 20px;


  border: 1px solid #d0d0d0;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  min-height: 70px;
  display: inline-block;
  text-align: center;
  box-shadow: 5px 4px 6px 3px #ddd;


}

.payment-div iframe {
  width: 90%;
}

.paypal {
  margin: 20px;
}

.img-formapago {
  width: 23%;
  margin-left: 10px;
  margin-right: 10px;

}

.formapago-info {
  margin-top: 20px;
}

/*Error pago*/
.error-pago-img {
  width: 20%;
  margin-top: 22px;
}

.error-pago-info {
  margin: 20px;
}

.error-pago-text {
  margin-top: 20px;
  font-size: x-large;
}

/*facturas y ticket y contrato global */

.lista {
  margin-top: 30px;
}

/* tarifas global */


.tarifas {
  display: inline !important;
}

.tarifasImagen {
  display: inline !important;
  margin-top: 20px;
}

.tarifasImagen ul {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0;
  display: inline;

}

.tarifas ul {
  margin-left: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  padding: 0;
  display: inline;

}

.nav-tarifas {
  margin-top: 30px;
  margin-left: 0px;

}

.operadores-text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.operadores {
  margin-bottom: 15px;
}

.tarifa-btn-group {
  text-align: center;
  margin-top: 20px;
}

.tarifa-btn {
  border: 1px solid #c8c7cc;
  border-radius: 20px;
  padding-bottom: 20px;
  margin: 5px;
  width: calc(50% - 10px);
  float: left;
  background: #e9ecef;
  box-shadow: 1px 1px #e0dfdf;
}

.tarifa-btn-icon {
  padding-bottom: 5px;
  color: #8bc34a;
}

.tarifa-btn-descripcion {
  margin-top: 20px;
}

/* tickets */
.boton-fab {
  --background: var(--color-corporativo);
  --padding-bottom: 50px;
  --margin-left: 50px
}

/*ticket nuevo*/
.ticket-nuevo {
  text-align: left;
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 10px;
  margin-top: 50px;
}

.ticket-asuntos {
  text-align: left;
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 10px;
}

.instrucciones-ticket-asuntos {
  margin-top: 10px;
  text-align: left;
  border-bottom: 1px solid #c8c7cc;
  padding-bottom: 10px;
}

.instrucciones-ticket-asuntos iframe {
  margin-top: 10px;
  width: 100%;
  height: auto;
}

.instrucciones-ticket-asuntos img {
  margin-top: 10px;
  width: 100% !important;
  height: auto !important;
}

.instrucciones-ticket-asuntos span {
  font-size: inherit !important;
  line-height: inherit !important;
}

.instrucciones-ticket-asuntos p {
  font-size: inherit !important;
  line-height: inherit !important;
}

.noticias-descripcion table {
  max-width: 283px !important;
  width: 287px !important;
  overflow-x: scroll;
  overflow-y: auto;
}

.noticias-descripcion td {
  display: inherit;
  max-width: 318px !important;
}

.infoAñadir {
  position: absolute;
  top: 10px;
  left: 30%;
  margin-top: -20px;
}

.asuntoTicket {
  margin-top: 5px;
  max-width: 300px;
}

.descripcion-ticket-label {
  text-align: left;
  width: 100%;
}

.descripcion-ticket {
  max-width: 820px;
  margin: 0px auto;
  border-bottom: 1px solid #c8c7cc;
}

.comment {
  float: left;
  width: 100%;
  height: auto;
}

.commenter {
  float: left;
}

.commenter img {
  width: 35px;
  height: 35px;
}

.comment-text-area {
  float: left;
  width: 100%;
  height: auto;
}

.textinput {
  float: left;
  width: 100%;
  min-height: 75px;
  outline: none;
  resize: none;
  border: 1px solid grey;
}



.sinImagen {
  width: 150px;
  height: 150px;
  margin-bottom: 25px;
  color: #c8c7cc;
}

.pieTicketNuevo {
  margin-bottom: 10%;
}

/*ticket detalle */

.btn-añadir {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  text-align: center;

}

.texto-comentario {
  font-weight: 400;
  font-size: 18px;
}

.comentarios {
  border-bottom: 1px solid #c8c7cc;
  margin-bottom: 5px;
  padding-bottom: 15px;
}

.comentarios-list {
  border: 1px solid #c8c7cc;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
  margin-bottom: 20px;

}

.comentarios-list-fecha {
  display: block;
  border-bottom: 1px solid #c8c7cc;
  text-align: right;
  margin-right: 10px;
}

.comentarios-list-autor {
  display: inline;
  text-align: left;
  margin-top: 15px;
  padding-top: 15px;
}

.comentarios-list-detalle {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
}

.no-coment {
  text-align: center;
  margin-top: 50px;
}

.autor {
  display: block;
  background-color: #BEF2FA;
  border-radius: 4px;
  border-bottom: 1px solid #c8c7cc;

  margin-right: 5px;
  height: 100%;
  width: 100%;
  text-align: left;

}

.sin-autor {
  display: block;
  background-color: #DEFAD3;
  border-bottom: 1px solid #c8c7cc;
  border-radius: 4px;
  text-align: left;

  padding-top: 5px;
}

/* comentario nuevo */
.Comentario-nuevo {
  text-align: left;
  margin-top: 30px;
  border-bottom: 1px solid #c8c7cc;
  margin-bottom: 30px;
}

.text-comentario {
  border: none;
}

.btt-cancelar {
  text-align: center;
  width: 45%;
  background-color: transparent;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #c8c7cc;
  margin: 5px;
}

.btn-enviar {
  text-align: center;
  width: 45%;
  background-color: var(--color-corporativo);
  padding: 10px;
  border: 1px solid var(--color-corporativo);
  border-radius: 3px;
  color: #ffffff;
  margin: 5px;
}

/*contrato detalle */
.link-consumo {
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: auto;
  max-width: 300px;
  min-height: 55px;
  font-family: inherit;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-decoration: none;
}

.consumo {

  border-radius: 5px;
  background-color: #ffffff80;
  color: var(--color-corporativo);
  border: 2px solid #ddd;
  padding: 10px;
  width: 100%;
  height: auto;
  max-width: 300px;
  min-height: 46px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
  margin-bottom: 10px;


}





/*Home*/


.iconos-home-div {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.icon-red-home {
  color: #b50505 !important;
}

.div-home {
  float: left;
  margin: 10px;
  width: calc(50% - 20px);
  background: #f3f3f3;
  border: none;
  border-radius: .5rem;
  color: #444;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 12px rgb(255 255 255 / 50%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
}

.div-tarifas {
  float: left;
  margin: 7px;
  width: calc(50% - 22px);
  background: #f3f3f3;
  border: none;
  border-radius: .5rem;
  color: #444;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 12px rgb(255 255 255 / 50%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
  height: 141px;
}

.div-tarifas .tarifa-btn-descripcion {
  font-size: 1.2rem;
}

.div-tarifas .iconostarifa {
  padding-top: 12px;
}

.div-tarifas .iconos {
  width: 45px;
  height: 45px
}

.div-home-cerrar {
  width: 88%;
  margin: 5% !important;
  height: 91px;
  float: left;

  background: #f3f3f3;
  border: none;
  border-radius: .5rem;
  color: #444;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 12px rgb(255 255 255 / 50%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
}


.link-entradas {

  color: black;

}

.icon-inner,
.ionicon,
svg {
  display: block;
  height: 100%;
  width: 100%;
}

.iconos {
  width: 39px;
  height: 36px;
  margin-right: 10px;
  padding-top: 19px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.texto {
  padding-left: 15px;
}

.salir {
  width: 100%;
  text-align: left;
  text-decoration: none;
  font-size: 22px !important;
  font-family: HelveticaNeue-Light !important;
  color: black;
  border: none;
  background-color: transparent !important;
  margin-left: 10px;
  border-bottom: 1px solid #c8c7cc;
  display: inline;
  margin-bottom: -10px;
}

.entradas-menu {
  font-size: 16px;
  margin-bottom: 10px;

}

.div-cerrar {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 15px;
  align-items: center;
  border-top: 1px solid #c8c7cc;
}

.entradas {
  margin-top: -15px;
  margin-left: 10px;
}

/*login*/
.Login {}

.idioma-select {
  margin-top: 5px;
  margin-bottom: 5px;

}

.Login_container {
  margin-bottom: 5px;
}


.btn-inicio {
  background-color: var(--color-corporativo);
  color: #ffffff;
  border: 1px solid var(--color-corporativo);
  border-radius: 3px;
  width: 90%;
  height: 49px;
  font-size: 18px;
  padding: 10px 10px;
  margin: 15px;
  text-align: center;
  display: block;
  margin-bottom: 35px;
  margin-top: 20px;
}

.label {
  max-width: 100%;
  text-align: left;
  width: 35%;
  margin-right: 15px;
}

.form-group {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #c8c7cc;
  padding-top: 8px;
  padding-bottom: 7px;
  padding-right: 8px;
}

.form-group2 {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid #c8c7cc;
  padding-top: 8px;
  padding-bottom: 7px;
  padding-right: 8px;
}

.form-input {
  border: none;
  margin-left: 5px;
  margin-top: 3px;
  text-align: left;
  display: block;
  width: 100%;
  line-height: 28px;
  padding-left: 5px;
}

.switch-button {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

.switch-button .switch-button__checkbox {
  display: none;
}

.switch-button .switch-button__label {
  width: 3rem;
  height: 2rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
  border-color: #000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  margin-left: 15px;

}

.switch-button .switch-button__label:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: var(--color-button);
  content: "";
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--color-black);

}

.switch-button .switch-button__checkbox:checked+.switch-button__label {
  background-color: var(--color-corporativo);

}

.switch-button .switch-button__checkbox:checked+.switch-button__label:before {

  transform: translateX(1rem);
}

.label-RemenberPassword {
  margin-left: 15px;
}

.link-recordar {
  text-decoration: none;
  color: var(--color-corporativo);
  border: none;
  background-color: transparent;
}

.links {
  line-height: 2;
  font-size: 18px;
  margin-right: 15px;

}

.pie {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.usuario-guardado {
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.cambiar-user {
  background-color: transparent;
  border: none;
  margin-left: 10px;
  color: var(--color-corporativo);
  line-height: 2;
  font-size: 18px;
}


/*Noticia portada*/

.modalNoticiaCerrar {
  background: none;
  border: none;
  text-align: right;
}

.anuncio {
  text-align: right;
}

.portada {
  box-shadow: none !important;

}

.noticia-portada-index {
  display: inline-block;
  width: 100%;
  margin: 0px auto;
  padding: 0px;
}

.modal {
  width: 95% !important;
  height: 95% !important;
  margin: 10px;
}

.modalbody .modal {
  transform: none;
  width: 90%;
  height: 90%;
  margin: 20px;
  max-height: 30px;
}

.interesa {
  text-align: center;
  /* margin-bottom: -12px; */
  /* margin-top: -15px; */
  font-weight: 100;
  background-color: #e69c03f5;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 3px;
  font-size: large;
  bottom: 17px;
  display: inline-block;
  width: calc(100% - 20px);
  position: absolute;
  z-index: 999999999;
  border: 2px solid #ffc104;
  /* padding: 0px 10px; */
  box-shadow: 1px 5px 5px #b7b7b7;
  transform: scale(1);
  animation: pulse-black 3s infinite;
  z-index: 1000;
}

.interesa p {
  height: 15px;
  color: #ffffff;
  text-shadow: 1px 1px 3px #b3b3b3;
  font-size: 17px;
  line-height: 29px;

}




@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(230, 156, 3, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(230, 156, 3, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(230, 156, 3, 0);
  }
}

.interesa-home {
  display: inline-block;
  width: 100%;
  margin: 0px auto;
  padding: 10px;
  position: relative;
}

/*formulario cliente*/

.formulario-cliente {
  margin: 15px;

}

.separador {
  margin-top: 30px;
  border-top: 1px solid #c8c7cc;
  padding-top: 5px;
  margin-bottom: 10px;
}

.volver {
  text-align: center;
}

.politica-privacidad {
  border-top: 1px solid #c8c7cc;
  margin-top: 20px;
  padding-top: 15px;

}

.privacidad-label {
  text-align: right;
  margin-left: 20px;
  color: blue;

}

.margin {
  margin-right: 9px;
}

/*Consumo detalle*/
.fecha-llamada {
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
}

.lista ul {
  padding: 0px;
}

.destino {
  text-align: right;
  margin-right: 10px;
  margin-top: 5px;
}

.destino2 {
  display: inline-block;
  text-align: right;
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

.precio_llamada {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
  float: left;
}

.llamadas {
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
}

/*formulario recordar contraseña*/
.form-contraseña {
  border-bottom: 1px solid #c8c7cc;
  text-align: center;
  padding-bottom: 10px;
  margin: 40px;
}

.texto-info-recordar {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  margin-top: 10%;
  text-align: left;
}

.btn-recordar {
  margin-top: 30px;
  margin-right: 20px;
}

.cancelar {
  margin-bottom: 20px;
}

.info-recordar {
  margin-top: 30px;
  text-align: left;
}

/*navbar navegacion atras*/
.nav-navegacion {
  text-align: left;
  margin-bottom: 15px;
  margin-top: 20%;
}

.detalles-grafico-accion {
  padding: 5px 0px 0px 0px;
}

.descripciontarifas {
  line-height: 1rem;
  text-align-last: start;
  display: inline-block;
  text-align: left;
}

span.nombre-tarifa-nom {
  line-height: 17px !important;
  display: inline-block;
  padding: 4px;
}

.tab-list {
  margin-bottom: 0px;
}

.tab-list-item {
  padding: 0.5rem !important;
}

.detalles button {
  padding: 4px;
}

.tab-list-active {
  background-color: #eeeeee !important;
  border-radius: 10px 10px 0px 0px;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.tab-content {
  margin-left: 0px !important;
  margin-right: 0px !important;
  background: #eee !important;
  padding-bottom: 20px;
  padding-top: 20px;
}

.imagenTa {
  margin-bottom: 20px;
}

.div-container {
  background: #fff;
}

.detalles-btn {
  padding: 4px 10px !important;
}

.iconostarifa {
  padding-top: 4px;
  padding-bottom: 10px;
}

.botonespass {
  margin-left: 0px !important;
}

.form-input:active,
.form-input:focus,
.form-input:visited,
.form-input:hover,
.form-input:focus-visible {
  border: 1px solid #8fb523 !important;
  outline: none;
}


/* latin-ext */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/NaPecZTIAOhVxoMyOr9n_E7fdM3mDbRS.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/NaPecZTIAOhVxoMyOr9n_E7fdMPmDQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.btonenviarCont {
  float: left;
  background: #f3f3f3;
  border: none;
  border-radius: .5rem;
  color: #444;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 12px rgb(255 255 255 / 50%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
  padding: 8px 0px;
  margin-bottom: 21px;
}

.btnrecuperar {
  background: #f3f3f3;
  border: none;
  border-radius: .5rem;
  color: #444;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 12px rgb(255 255 255 / 50%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
  width: 50%;
  float: left;
  padding: 8px 0px;
  margin-bottom: 21px;
  display: inline-block;
  line-height: 28px;
  margin: 5px 0px;
}

.formularioRecuperara {
  overflow: hidden;
}

.info-huella {
  margin: 20px;
  text-align: center;
}

/*alertas*/
.avisos {
  --width: 90%;
  --max-width: 90%;
}

.avisos .alert-button.sc-ion-alert-md {
  color: white;
  background-color: var(--color-corporativo);
  width: 20%;
  border-radius: 5px;
  justify-content: center !important;
  text-transform: none;
}

.avisos .alert-button-inner.sc-ion-alert-md {
  justify-content: center !important;

}

/*Noticia del home*/
.noticia-portada {
  display: inline-block;
  width: 100%;
  margin: 0px auto;
  padding: 10px;
}

.noticias-descripcionhome table {
  max-width: 283px !important;

  width: 287px !important;
  overflow-x: scroll;
  overflow-y: auto;
}

.noticias-descripcionhome iframe {
  width: 100%;
  height: auto;
  min-height: 190px;
}

.noticias-descripcionhome td {
  display: inherit;
  max-width: 318px !important;
}

.noticias-descripcionhome img {
  width: 100% !important;
  height: auto !important;
}

.noticia-portada-index {
  display: inline-block;
  width: 100%;
  margin: 0px auto;
  padding: 0px;
}

.obligarAsunto {
  color: red;
  margin-top: 10px;
}

.select-ticket {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-style: inset !important;
  border: 2px;
  position: relative;
  left: 90px;
  top: 55px
}

.select-ticket-vacio {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-style: inset !important;
  border: 2px;
  position: relative;
  left: 90px;
  bottom: 25px;
}

.lupa {
  color: var(--color-corporativo);
  position: relative;
  left: 150px;

}

.input-ticket {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-style: inset !important;
  border: 2px;
}

.div-ticket-select {
  margin-top: 0px;
}

.fab-buton {
  margin-bottom: -17px;
}

.activar-roaming {
  margin-top: 15px;
  text-align: left;
}

.float-roaming {
  text-align: right;
}

.herramientas-list-sim {
  margin-top: 50px;
  margin-bottom: 25px;
  text-align: left;
  background: #d3cdcd52;
  padding: 11px;
  border-radius: 5px;
  box-shadow: 3px 3px 13px #c1bbbb;
  font-size: 20px;
}

.herramientas-list {
  margin-top: 10px;
  margin-bottom: 25px;
  text-align: left;
  background: #d3cdcd52;
  padding: 11px;
  border-radius: 5px;
  box-shadow: 3px 3px 13px #c1bbbb;
  font-size: 20px;
}

.nombrePromo {
  color: var(--color-corporativo);
  text-align: center;
  font-size: larger;
}

.codigoPromo {
  text-align: center;
  color: var(--color-corporativo);
}

.sim-card-margin {
  margin-left: 10px;
}

.imagenPromo {
  width: 300px;

  margin-top: -90px;
}

.icon-sim {
  font-size: 30px;
  color: var(--color-corporativo);
  margin-right: 15px;
}

.tarjeta-sim {
  width: 300px;
}

.sim {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.contenedor-sim {
  margin-top: auto;
  width: 300px;
  height: 200px;
  background-image: url("../Imagenes/sim.png");
  background-size: cover;
  box-shadow: 3px 3px 13px grey;
  border-radius: 18px;
}

.pin2 {
  margin-left: 30px;
  display: block;
  font-size: 15px;
}


.pin2-2 {
  margin-left: 30px;
  display: block;
  font-size: 15px;
  margin-top: 80px;
}

.pin1 {
  margin-top: 80px;
  margin-left: 30px;
  display: block;
  font-size: 15px;
}

.puk1 {
  margin-left: 30px;
  display: block;
  font-size: 15px;
}

.sim-codigo {
  margin-right: 15px;
  font-size: 15px;
  margin-top: 25px;
}

.puk2-mar {
  margin-left: 5px;
}

.puk-mar {
  margin-left: 14px;
}

.puk1-mar {
  margin-left: 10px;
}

.titulo-puk {
  color: var(--color-corporativo);
}

.enviar-pass-sim {
  float: right;
  margin-top: 20px;
  background: #f3f3f3;
  border-radius: 0.5rem;
  color: #444;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 12px rgb(255 255 255 / 50%), -6px -6px 10px rgb(255 255 255 / 50%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%);
  padding: 9px 33px;
  width: 95%;
}

.form-input-sim {
  border: none;
  margin-left: 5px;
  margin-top: 15px;
  margin-bottom: 35px;
  text-align: left;
  display: block;
  width: 100%;
  line-height: 28px;
  padding-left: 5px;
  border-bottom: 1px solid var(--color-corporativo);
}

.tabsclientes {
  margin-top: 20px;
  margin-bottom: 20px;
}

.docu-cliente {
  text-align: left;
  background-color: white;
}

.form-group-crear-cliente {
  text-align: left;
  margin-bottom: 5px;
}

.label-cliente {
  margin-left: 6px !important;
}

.selec-tipo-persona {
  width: 100%;
}

.check-potencial {
  margin-left: 10px;
}

.ofertas-cliente {
  text-align: left;
  margin-left: 5px;
  background-color: white;
}

.ofertas-estado {
  text-align: right;
  margin-right: 15px;
}

.tipoclienteFicha {
  margin-left: 15px;
  border-radius: 5px;
}

.div-cliente-potencial {
  text-align: right;
  margin-right: 15px;
  margin-top: 15px;
}

.tipoclientebtn {
  display: inline !important;
  width: 32% !important;
}

.citas-div {
  margin-bottom: 10px;
  margin-top: 15px;
}

.listado-clientes a {
  color: black !important
}


.icon-listado-clientes {
  margin-left: 15px;
  margin-right: 5px;
}

.estado-oferta {
  text-align: right;
  margin-right: 15px;
}

.class-verde {
  color: green;
}

.class-rojo {
  color: red;
}

.class-grey {
  color: darkgrey;
}

.class-naranja {
  color: orange;
}

.componente-oferta {
  margin-top: 15px;

}

.componente-oferta .ofertas-estado {
  margin-top: 5px;

}

.subir-doc {
  margin-top: 15px;
}