.noticias-descripcion table {
    max-width: 283px !important;
    width: 287px !important;
    overflow-x: scroll;
    overflow-y: auto;
}

.asunto-list table {
    max-width: 283px !important;
    width: 287px !important;
    overflow-x: scroll;
    overflow-y: auto;
    padding: auto;
}

.asunto-list iframe {
    width: 100%;
    height: auto;
}

.noticias-descripcion iframe {
    width: 100%;
    height: auto;
}

.noticias-descripcion td {
    display: inherit;
    max-width: 318px !important;
    padding: 0px !important;
}

.asunto-list td {
    max-width: 318px !important;
    padding: 0px !important;
}

.asunto-list img {
    width: 100% !important;
    height: auto !important;
}

.noticias-descripcion img {
    width: 100% !important;
    height: auto !important;
}

.span {
    font-size: inherit !important;
    line-height: inherit !important;
}

.p {
    font-size: inherit !important;
    line-height: inherit !important;
}



.noticias-fecha {
    font-style: italic;
    font-size: 0.9em;
}

.noticias-list-li {
    margin: 0px;
}

/*Noticias*/
.noticias-list {
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.noticias-titulo {
    border-bottom: 1px solid #c8c7cc;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: var(--color-corporativo);
    font-weight: bold;
}

.num-pagina {
    text-align: right;
    margin-right: 5px;
}

.paginacion {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: right;
}

.pagination>li>a {
    background-color: white;
    color: black;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    color: #5a5a5a;
    background-color: #eee;
    border-color: #ddd;
}



.pagination-sm .page-link {
    padding: 0.55rem 0.8rem !Important;
    font-size: 0.875rem;
    line-height: 1.5;
}